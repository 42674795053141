import React from 'react'
import Navbar from '../components/navbar'
import Features from '../components/features'
import Footer from '../components/footer'

export default function features() {
  return (
    <div>

        <Navbar/>
        <Features />
        <Footer/>
    </div>
  )
}
